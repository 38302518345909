<!--
    Market Movers Table
-->
<template>
  <section style="margin-top: 130px;">
    <p class="title" style="margin-bottom: 30px;"> Market Movers </p>
    <a href="javascript:;" class="title">
      <span :class="{'non-active-tab': type !== 'gainers'}" style="margin-right: 30px;" @click="type = 'gainers'"> Gainers </span>
      <span :class="{'non-active-tab': type !== 'losers'}" @click="type = 'losers'"> Losers </span>
    </a>
    <b-table
      ref="table"
      :data="markets && markets.length > 0 ? markets : []"
      :hoverable="true"
      :default-sort-direction="sortDirection"
      :default-sort="sortField"
      backend-sorting
      @sort="sorting"
    >
      <template
        slot-scope="props"
      >
        <b-table-column
          field="symbol"
          label="Symbol"
          class="no-border symbol_column"
          width="100"
          sortable
        >
          <router-link

            id="symbol"
            tag="div"
            :to="'/market/' + props.row.type + '/' + props.row.symbol"
            :class="{
              'card is-shady has-text-centered p-sm has-text-white': true,
              'positive-gradient-1':
                props.row.changePercent >= 0 && props.row.changePercent < 2,
              'positive-gradient-2': props.row.changePercent >= 2,
              'negative-gradient-2': props.row.changePercent <= -2,
              'negative-gradient-1':
                props.row.changePercent < 0 && props.row.changePercent > -2
            }"
          >
            {{ props.row.symbol }}
          </router-link>
        </b-table-column>

        <b-table-column
          field="name"
          label="Name"
          class="market_name_column"
          sortable
        >
          <p>{{ truncate(props.row.companyName, 50) }}</p>
        </b-table-column>

        <b-table-column
          field="sector"
          label="Sector"
          width="100"
        >
          <p>{{ firstLetterUppercase(props.row.sector || 'Stock') }}</p>
        </b-table-column>

        <b-table-column
          field="close"
          label="Price"
          width="100"
          numeric
          sortable
        >
          <p class="is-6">
            {{ roundFormatter(props.row.close) }}
          </p>
        </b-table-column>

        <b-table-column
          field="week52High"
          label="Weekly High"
          width="100"
          numeric
          sortable
        >
          <p class="is-6">
            {{ roundFormatter(props.row.week52High) }}
          </p>
        </b-table-column>

        <b-table-column
          field="week52Low"
          label="Weekly Low"
          width="100"
          numeric
          sortable
        >
          <p class="is-6">
            {{ roundFormatter(props.row.week52Low) }}
          </p>
        </b-table-column>

        <b-table-column
          field="marketCap"
          label="Market Cap"
          width="100"
          numeric
          sortable
        >
          <p class="is-6">
            {{ numberSuffixFormatter(props.row.marketCap) }}
          </p>
        </b-table-column>

        <b-table-column
          field="ytdChange"
          label="Yearly Change %"
          width="100"
          numeric
          sortable
        >
          <p class="is-6">
            {{ roundFormatter(props.row.ytdChange) }}
          </p>
        </b-table-column>

        <b-table-column
          field="changePercent"
          label="Change %"
          width="100"
          numeric
          sortable
        >
          <p class="is-6">
            {{ parseFloat(props.row.changePercent).toFixed(2) }} %
          </p>
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>No markets to see here yet.</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>
	import Markets from '../services/Markets';

	export default {
		name: 'MarketMoversTable',
		data() {
			return {
			    movers: {},
				type: 'gainers',
			    sortDirection: 'desc',
			    sortField: 'changePercent',
				markets: []
			}
		},
	    watch: {
	      type() {
	        this.initialize();
	      }
		},
		mounted() {
			this.initialize();
		},
		methods: {
			async initialize() {
				try {
					this.movers.gainers = (await Markets.getMarketMovers('gainers')).data;
					this.movers.losers = (await Markets.getMarketMovers('losers')).data;
					this.markets = this.movers[this.type];
					this.sorting(this.sortField, this.sortDirection);
				} catch (e) {
					console.log("Error", e)
				}
			},
			sorting(field, direction) {
				console.log(field, direction);
				this.sortDirection = direction;
				this.sortField = field;
				this.markets = this.movers[this.type].sort((m1, m2) => {
					if (m1[field] < m2[field]) {
						return direction === 'desc' ? 1 : -1;
					} else if (m1[field] > m2[field]) {
						return direction === 'desc' ? -1 : 1;
					}
					return 0;
				}).slice(0, 15);
			}
		},
	};
</script>

<style scoped>
	.watchlist {
		text-align: center;
	}
	.non-active-tab {
		color: #8e918e !important;
	}
</style>
