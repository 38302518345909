var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticStyle:{"margin-top":"130px"}},[_c('p',{staticClass:"title",staticStyle:{"margin-bottom":"30px"}},[_vm._v(" Market Movers ")]),_c('a',{staticClass:"title",attrs:{"href":"javascript:;"}},[_c('span',{class:{'non-active-tab': _vm.type !== 'gainers'},staticStyle:{"margin-right":"30px"},on:{"click":function($event){_vm.type = 'gainers'}}},[_vm._v(" Gainers ")]),_c('span',{class:{'non-active-tab': _vm.type !== 'losers'},on:{"click":function($event){_vm.type = 'losers'}}},[_vm._v(" Losers ")])]),_c('b-table',{ref:"table",attrs:{"data":_vm.markets && _vm.markets.length > 0 ? _vm.markets : [],"hoverable":true,"default-sort-direction":_vm.sortDirection,"default-sort":_vm.sortField,"backend-sorting":""},on:{"sort":_vm.sorting},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{staticClass:"no-border symbol_column",attrs:{"field":"symbol","label":"Symbol","width":"100","sortable":""}},[_c('router-link',{class:{
            'card is-shady has-text-centered p-sm has-text-white': true,
            'positive-gradient-1':
              props.row.change_percent >= 0 && props.row.change_percent < 2,
            'positive-gradient-2': props.row.change_percent >= 2,
            'negative-gradient-2': props.row.change_percent <= -2,
            'negative-gradient-1':
              props.row.change_percent < 0 && props.row.change_percent > -2
          },attrs:{"id":"symbol","tag":"div","to":'/market/' + props.row.type + '/' + props.row.symbol}},[_vm._v(" "+_vm._s(props.row.symbol)+" ")])],1),_c('b-table-column',{staticClass:"market_name_column",attrs:{"field":"name","label":"Name","sortable":""}},[_c('p',[_vm._v(_vm._s(_vm.truncate(props.row.name, 50)))])]),_c('b-table-column',{attrs:{"field":"sector","label":"Sector","width":"100"}},[_c('p',[_vm._v(_vm._s(_vm.firstLetterUppercase(props.row.type)))])]),_c('b-table-column',{attrs:{"field":"close","label":"Price","width":"100","numeric":"","sortable":""}},[_c('p',{staticClass:"is-6"},[_vm._v(" "+_vm._s(_vm.roundFormatter(props.row.close))+" ")])]),_c('b-table-column',{attrs:{"field":"change","label":"Change","width":"100","numeric":"","sortable":""}},[_c('p',{staticClass:"is-6"},[_vm._v(" "+_vm._s(_vm.roundFormatter(props.row.change))+" ")])]),_c('b-table-column',{attrs:{"field":"change_percent","label":"Change %","width":"100","numeric":"","sortable":""}},[_c('p',{staticClass:"is-6"},[_vm._v(" "+_vm._s(_vm.roundFormatter(props.row.change_percent))+" ")])]),_c('b-table-column',[_c('img',{attrs:{"src":'https://s3.eu-central-1.amazonaws.com/cdn.morpher.com/images/charts/' + ((props.row.change_percent < 0) ? 'RED' : 'GREEN') + '_' + _vm.formatMarketId(props.row.type, props.row.symbol) + '.png'}})])]}}])},[_c('template',{slot:"empty"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v("No markets to see here yet.")])])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }