<template>
  <section class="hero background-grey is-fullheight">
    <Navbar />

    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column pt-0 is-hidden-mobile">
            <MarketMoversTable />
            <OwnMarketMoversTable />
          </div>
        </div>
      </div>
    </div>

    <div class="hero-foot">
      <div class="container is-voffset is-center-mobile">
        <div class="columns center">
          <div class="column">
            <div class="card is-market-card">
              <div class="card-content">
                <figure>
                  <img class="is-26" src="@/assets/img/fitbit_logo.png" alt="Fitbit FIT Logo">
                </figure>
                <p class="title is-6 is-return-header">Profit</p>
                <p class="subtitle is-3 is-return-value">+ 14.5%</p>
                <p class="is-grey-text">Q1 2019</p>
                <h4>After hours earnings slump.</h4>
                <div class="image">
                  <img src="@/assets/img/fitbit_chart.svg" alt="Fitbit short sell return chart.">
                </div>
              </div>
            </div>
          </div>
          <div class="column is-1 is-hidden-mobile" />

          <div class="column market-mobile-hide">
            <div class="card is-market-card">
              <div class="card-content">
                <figure>
                  <img class="is-26" src="@/assets/img/tesla_logo.png" alt="Tesla TSLA Logo">
                </figure>
                <p class="title is-6 is-return-header">Profit</p>
                <p class="subtitle is-3 is-return-value">+ 5.83%</p>
                <p class="is-grey-text">Q1 2019</p>
                <h4>Breaking news pre-market.</h4>
                <div class="image">
                  <img src="@/assets/img/tesla_chart.svg" alt="Tesla buy pre-market return chart.">
                </div>
              </div>
            </div>
          </div>
          <div class="column is-1 is-hidden-mobile" />

          <div class="column is-hidden-mobile">
            <div class="card is-market-card">
              <div class="card-content">
                <figure>
                  <img class="is-26" src="@/assets/img/bitcoin_logo.png" alt="Bitcoin BTC Logo">
                </figure>
                <p class="title is-6 is-return-header">Profit</p>
                <p class="subtitle is-3 is-return-value">+ 9.46%</p>
                <p class="is-grey-text">Q1 2019</p>
                <h4>Small crypto bubble crash.</h4>
                <div class="image">
                  <img src="@/assets/img/bitcoin_chart.svg" alt="Bitcoin BTC short sell return chart.">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Navbar from "@/components/partials/Navbar.vue"
// import Email from "@/components/partials/Email.vue";
// import Chart from "@/components/Chart.vue";
// import BuySellCard from "./BuySellCard";
import MarketMoversTable from "./MarketMoversTable";
import OwnMarketMoversTable from "./OwnMarketMoversTable";

export default {
	name: "MarketMoversHero",
	components: {
	    OwnMarketMoversTable,
	    MarketMoversTable,
		// BuySellCard,
		// Chart,
		Navbar,
		// Email
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

	@media only screen and (max-width: 1023px) {
		.is-center-touch {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	@media only screen and (max-width: 768px) {
		.is-center-mobile {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.hero.hero.is-fullheight {
			min-height: initial;
		}
	}
	@media only screen and (max-width: 500px) {
		.market-mobile-hide {
			display: none;
		}
	}
	@media only screen and (max-width: 420px) {
		.has-text-left-small {
			text-align: left !important;
		}
	}
	@media only screen and (min-height: 1200px) {
		.hero.is-fullheight {
			min-height: 1200px;
		}
	}

	.background-grey {
		background-color: #F7FAF9;
		/* background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); */
		/* background-image: radial-gradient(closest-side at 73% 147%, #EADFDF 59%, #ECE2DF 100%), radial-gradient(91% 146%, rgba(255,255,255,0.65) 47%, rgba(0,0,0,0.50) 100%);
		background-blend-mode: screen;  */

	}
	.pt-0 {
		padding-top: 0px;
		margin-top: -100px;
	}
	.pl-0 {
		padding-left: 0px;
	}
	.pt-35 {
		padding-top: 35px;
	}
	.mt-10 {
		margin-top: 10px;
	}
	.is-voffset {
		margin-bottom: -180px;
	}
	.is-26 {
		max-height: 26px;
	}
	.is-market-card {
		box-shadow: 0 5px 20px 0 rgba(182,191,210,0.30);
		border-radius: 10px;
		transition-delay: 0s;
		transition-duration: .3s;
		transition-property: all;
		transition-timing-function: cubic-bezier(.25,.46,.45,.94);
	}
	.is-market-card:hover {
		box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);
		transition-delay: 0s;
		transition-duration: .3s;
		transition-property: all;
		transition-timing-function: cubic-bezier(.25,.46,.45,.94);
	}
	.is-return-header {
		color: #8F8F8F;
		margin-top: 10px;
		/* margin-bottom: 10px; */
	}
	.is-return-value {
		font-weight: 600;
		margin-bottom: 10px !important;
	}
</style>
