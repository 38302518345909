<!--
    Own Market Movers Table
-->
<template>
  <section style="margin-top: 130px;">
    <p class="title" style="margin-bottom: 30px;"> Market Movers </p>
    <a href="javascript:;" class="title">
      <span :class="{'non-active-tab': type !== 'gainers'}" style="margin-right: 30px;" @click="type = 'gainers'"> Gainers </span>
      <span :class="{'non-active-tab': type !== 'losers'}" @click="type = 'losers'"> Losers </span>
    </a>
    <b-table
      ref="table"
      :data="markets && markets.length > 0 ? markets : []"
      :hoverable="true"
      :default-sort-direction="sortDirection"
      :default-sort="sortField"
      backend-sorting
      @sort="sorting"
    >
      <template
        slot-scope="props"
      >
        <b-table-column
          field="symbol"
          label="Symbol"
          class="no-border symbol_column"
          width="100"
          sortable
        >
          <router-link

            id="symbol"
            tag="div"
            :to="'/market/' + props.row.type + '/' + props.row.symbol"
            :class="{
              'card is-shady has-text-centered p-sm has-text-white': true,
              'positive-gradient-1':
                props.row.change_percent >= 0 && props.row.change_percent < 2,
              'positive-gradient-2': props.row.change_percent >= 2,
              'negative-gradient-2': props.row.change_percent <= -2,
              'negative-gradient-1':
                props.row.change_percent < 0 && props.row.change_percent > -2
            }"
          >
            {{ props.row.symbol }}
          </router-link>
        </b-table-column>

        <b-table-column
          field="name"
          label="Name"
          class="market_name_column"
          sortable
        >
          <p>{{ truncate(props.row.name, 50) }}</p>
        </b-table-column>

        <b-table-column
          field="sector"
          label="Sector"
          width="100"
        >
          <p>{{ firstLetterUppercase(props.row.type) }}</p>
        </b-table-column>

        <b-table-column
          field="close"
          label="Price"
          width="100"
          numeric
          sortable
        >
          <p class="is-6">
            {{ roundFormatter(props.row.close) }}
          </p>
        </b-table-column>


        <b-table-column
          field="change"
          label="Change"
          width="100"
          numeric
          sortable
        >
          <p class="is-6">
            {{ roundFormatter(props.row.change) }}
          </p>
        </b-table-column>

        <b-table-column
          field="change_percent"
          label="Change %"
          width="100"
          numeric
          sortable
        >
          <p class="is-6">
            {{ roundFormatter(props.row.change_percent) }}
          </p>
        </b-table-column>

        <b-table-column>
          <img
            :src="'https://s3.eu-central-1.amazonaws.com/cdn.morpher.com/images/charts/' + ((props.row.change_percent < 0) ? 'RED' : 'GREEN') + '_' + formatMarketId(props.row.type, props.row.symbol) + '.png'"
          >
        </b-table-column>


      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>No markets to see here yet.</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>
	import Markets from '../services/Markets';

	export default {
		name: 'OwnMarketMoversTable',
		data() {
			return {
			    movers: [],
				type: 'gainers',
			    sortDirection: 'desc',
			    sortField: 'changePercent',
				markets: []
			}
		},
	    watch: {
	      type() {
	        this.initialize();
	      }
		},
		mounted() {
			this.initialize();
		},
		methods: {
			async initialize() {
				try {
					const result = await Markets.getOwnMarkets();
					if (result.data.success) {
						this.movers = result.data.markets
						  .filter(market => market.change_percent !== undefined && market.type !== 'exotic')
						  .sort(this.compareChange);

						if (this.type === 'gainers') {
							this.markets = this.movers.slice(0, 10);
						} else {
							this.markets = this.movers.splice(-10)
						}
						this.sorting(this.sortField, this.sortDirection);
					}
				} catch (e) {
					console.log("Error", e)
				}
			},
			compareChange(m1, m2) {
				let comparison = 0;
				if (m1.change_percent < m2.change_percent) {
					comparison = 1;
				} else if (m1.change_percent > m2.change_percent) {
					comparison = -1;
				}
				return comparison;
			},
			sorting(field, direction) {
				this.sortDirection = direction;
				this.sortField = field;
				this.markets = this.markets.sort((m1, m2) => {
					if (m1[field] < m2[field]) {
						return direction === 'desc' ? 1 : -1;
					} else if (m1[field] > m2[field]) {
						return direction === 'desc' ? -1 : 1;
					}
					return 0;
				}).slice(0, 15);
			}
		},
	};
</script>

<style scoped>
	.watchlist {
		text-align: center;
	}
	.non-active-tab {
		color: #8e918e !important;
	}
</style>
